import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/2023/gandijayanti/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/2023/gandijayanti/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/2023/gandijayanti/3.jpg';
// import p4 from 'assests/Photos/SchoolProgram/2023/gandijayanti/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';

const GandiJayanti2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/gandijayanti/1.webp`;
const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/gandijayanti/2.webp`;
const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/gandijayanti/3.webp`;
const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/gandijayanti/4.webp`;


    const photos = [
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 2,
        },
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 2,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 2,
        }, 
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                GANDHI JAYANTHI 	
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 9 & 10  Date: 3 OCTOBER 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The students of Classes 9 and 10 presented a special assembly on Gandhi Jayanthi on Tuesday, 03 October 2023. They delivered an inspirational speech on Mahatma Gandhi, the Father of the Nation and his contributions to the Freedom Movement of India following the path of Non-Violence and Satyagraha. The students sung melodious rendition of bhajans. 
                                    <br></br>
                                    The assembly gained momentum with a teacher talk and a pledge was taken to reaffirm on making our country, India free from the hazel of tobacco thereby instilling the values embarked by Mahatma Gandhiji.  A quiz was also conducted to highlight Gandhiji’s accomplishments.  
                                   
                                </Typography>
                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “हिन्दी हमारी शान है, हमारा अभिमान है |”

                                <br />
                            </Typography> */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 400 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default GandiJayanti2023;